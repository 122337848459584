import classNames from "classnames"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import { escapeRegExp } from "../helpers/format"
import useError from "../hooks/useError"
import { Tag, TagsService } from "../sdk/certifications"
import Icon from "./Icon"
import QuestionTag from "./QuestionTag"
import Spinner from "./Spinner"

type Props = {
  questionTags: Tag[]
  tagSuggestions: Tag[]
  upsertTag: (payload: { tagId: string, name: string }) => void
  deleteTag: (index: number) => void
  reloadTags: () => Promise<void>
  allowTagCreation?: boolean
}

const QuestionTags: FC<Props> = ({ questionTags, tagSuggestions, upsertTag, deleteTag, reloadTags, allowTagCreation = true }) => {
  const { handleError } = useError()

  const tagsSuggestionsRef = useRef<HTMLDivElement>(null)
  const [addingTag, setAddingTag] = useState(false)
  const [tagSearchInput, setTagSearchInput] = useState("")
  const [tagSearchIndex, setTagSearchIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (tagsSuggestionsRef.current) {
      tagsSuggestionsRef.current.children[tagSearchIndex].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
    }
  }, [tagSearchIndex])

  useEffect(() => {
    if (!addingTag) {
      setTagSearchIndex(0)
      setTagSearchInput("")
    }
  }, [addingTag])

  const filteredTags = useMemo(() => tagSuggestions.filter(({ name, tagId }) => {
    return !questionTags.some(tag => tagId === tag.tagId) && (!tagSearchInput || name.match(new RegExp(`${escapeRegExp(tagSearchInput)}(\\w+)?`, "gi")))
  }), [questionTags, tagSuggestions, tagSearchInput])

  const handleNewTag = async(): Promise<void> => {
    if (filteredTags.length) {
      upsertTag(filteredTags[tagSearchIndex])
    } else if (tagSearchInput && allowTagCreation) {
      setIsLoading(true)
      try {
        const name = tagSearchInput.toUpperCase()
        const { data: { tagId } } = await TagsService.upsertTag({ updates: { name } })
        upsertTag({ tagId, name })
        await reloadTags()
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }
    setAddingTag(false)
  }

  return <div className={"ml-auto flex gap-3"}>
    {
      questionTags.map((tag, index) => {
        return <QuestionTag
          key={`tagList-${tag.tagId}`}
          tag={tag}
          deleteTag={(): void => deleteTag(index)}
          upsertTag={upsertTag}
          reloadTags={reloadTags}
          allowEdit={allowTagCreation}
        />
      })
    }
    <div className={classNames("tag tag--primary tag--outline relative")}
      style={{ width: addingTag ? `${20 + (tagSearchInput.length > 20 ? tagSearchInput.length - 20 : 0)}ch` : "45px" }}
    >
      <div className="custom-tag">
        {
          addingTag ? <>
            <input
              className={"tagsEditor__custom-tag-input w-full"}
              value={tagSearchInput}
              onChange={(event): void => {
                setTagSearchInput(event.target.value)
              }}
              autoFocus
              disabled={isLoading}
              onBlur={(): void => setAddingTag(false)}
              onKeyDown={async(event): Promise<void> => {
                switch (event.key) {
                  case "ArrowDown": {
                    if (tagSearchIndex < filteredTags.length - 1) {
                      setTagSearchIndex(index => index + 1)
                    }
                    break
                  }
                  case "ArrowUp": {
                    if (tagSearchIndex > 0) {
                      setTagSearchIndex(index => index - 1)
                    }
                    break
                  }
                  case "Enter": {
                    void handleNewTag()
                  }
                }
              }}
            />
            {
              isLoading ? <Spinner size={14} className={"inline-block ml-1"}/> :
                <Icon
                  size={16}
                  name={"check"}
                  className={"cursor-pointer inline-block stroke-2 -mr-2"}
                  onMouseDown={handleNewTag}
                />
            }
          </> : <Icon size={16} name={"plus"} className={"cursor-pointer stroke-2"} onClick={(): void => setAddingTag(true)}/>
        }
      </div>
      {
        addingTag && filteredTags.length > 0 && <div ref={tagsSuggestionsRef} className={"absolute w-full max-h-52 overflow-y-scroll left-0 text-left mt-3 bg-white border rounded-lg border-slate-200"}>
          {
            filteredTags.map((tag, index) => {
              return <div
                key={`suggestion-${tag.tagId}`}
                className={classNames("text-black font-light p-3", tagSearchIndex === index && "bg-indigo-500 text-white")}
                onMouseDown={handleNewTag}
                onMouseEnter={(): void => setTagSearchIndex(index)}
              >
                {tag.name}
              </div>
            })
          }
        </div>
      }
    </div>
  </div>
}

export default QuestionTags