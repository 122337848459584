/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloseApplication200ResponseSchema } from '../models/CloseApplication200ResponseSchema';
import type { CloseApplicationRequestSchema } from '../models/CloseApplicationRequestSchema';
import type { DeleteApplication200ResponseSchema } from '../models/DeleteApplication200ResponseSchema';
import type { DeleteApplicationRequestSchema } from '../models/DeleteApplicationRequestSchema';
import type { ListApplications200ResponseSchema } from '../models/ListApplications200ResponseSchema';
import type { ListApplicationsRequestSchema } from '../models/ListApplicationsRequestSchema';
import type { UpsertApplication200ResponseSchema } from '../models/UpsertApplication200ResponseSchema';
import type { UpsertApplicationRequestSchema } from '../models/UpsertApplicationRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApplicationsService {

  /**
   * deleteApplication
   * Delete Application
   * @param requestBody
   * @returns DeleteApplication200ResponseSchema DeleteApplication200Response
   * @throws ApiError
   */
  public static deleteApplication(
    requestBody?: DeleteApplicationRequestSchema,
  ): CancelablePromise<DeleteApplication200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/applications/deleteApplication',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * closeApplication
   * Close Application
   * @param requestBody
   * @returns CloseApplication200ResponseSchema CloseApplication200Response
   * @throws ApiError
   */
  public static closeApplication(
    requestBody?: CloseApplicationRequestSchema,
  ): CancelablePromise<CloseApplication200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/applications/closeApplication',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * listApplications
   * List users applications
   * @param requestBody
   * @returns ListApplications200ResponseSchema ListApplications200Response
   * @throws ApiError
   */
  public static listApplications(
    requestBody?: ListApplicationsRequestSchema,
  ): CancelablePromise<ListApplications200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/applications/listApplications',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * upsertApplication
   * Update or Create application
   * @param requestBody
   * @returns UpsertApplication200ResponseSchema UpsertApplication200Response
   * @throws ApiError
   */
  public static upsertApplication(
    requestBody?: UpsertApplicationRequestSchema,
  ): CancelablePromise<UpsertApplication200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/applications/upsertApplication',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
