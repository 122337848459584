import { FC, useState } from "react"
import { Certification, Set, SetsService } from "../sdk/certifications"
import Table from "./Table"
import { certificationColors, certificationsMap } from "../constants/certification"
import { MINUTE } from "../helpers/time"
import { useNavigate } from "react-router-dom"
import Button from "./Button"
import Modal from "./Modal"
import { localStorage } from "../helpers/storage"
import useError from "../hooks/useError"
import useAuth from "../hooks/useAuth"
import Tag from "./Tag"
import DropdownMenu from "./DropdownEditMenu"

type Props = {
  sets: Set[]
  reloadSets: () => Promise<void>
}

const SetsList: FC<Props> = ({ sets, reloadSets }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { handleError } = useError()
  const [selectedSet, setSelectedSet] = useState<{ setId: string, certification: Certification, deprecated?: boolean } | null>(null)

  const [randomizeOrderModalOpen, setRandomizeOrderModalOpen] = useState<boolean>(false)
  const [existingProgressModalOpen, setExistingProgressModalOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deprecateModalOpen, setDeprecateModalOpen] = useState(false)

  return <>
    <Table
      headings={["Certification", "Name", "Pass Percentage", "Time Limit", "Actions"]}
      fixedColumns={["Actions"]}
      rows={
        sets.map(({ setId, name, certification, passPercentage, timeLimit, deprecated }) => {
          return {
            id: setId,
            children: [
              <Tag outline theme={certificationColors[certification]}>{certificationsMap[certification]}</Tag>,
              <span className={"font-bold"}>
                {deprecated && <span className={"text-red-600"}>[Deprecated]&nbsp;</span>}
                {name}
              </span>,
              `${passPercentage}%`,
              `${Math.round(timeLimit / MINUTE)} minutes`,
              <div className={"flex gap-3 items-center"}>
                <Button
                  theme={"secondary"}
                  onClick={(): void => navigate(`/sets/${certification}/${setId}?mode=exam&deleteCache=true&randomizeOrder=true&question=1`)}
                >
                  Start in exam mode
                </Button>
                <Button
                  onClick={(): void => {
                    setSelectedSet({ setId, certification })
                    if (localStorage.getItem(`set.${setId}`, "safe")) {
                      setExistingProgressModalOpen(true)
                    } else {
                      setRandomizeOrderModalOpen(true)
                    }
                  }}
                >
                  Start as training
                </Button>
                {
                  user?.isAdmin && <>
                    <DropdownMenu align={"right"}>
                      {
                        <li
                          className={"cursor-pointer font-medium text-gray-600 hover:text-gray-800 flex py-1 px-3"}
                          onClick={(): void => {
                            setSelectedSet({ setId, certification, deprecated })
                            setDeprecateModalOpen(true)
                          }}
                        >
                          {!deprecated ? "Deprecate" : "Republish"}
                        </li>
                      }
                      <li
                        className={"cursor-pointer font-medium text-red-500 hover:text-red-600 flex py-1 px-3"}
                        onClick={(): void => {
                          setSelectedSet({ setId, certification })
                          setDeleteModalOpen(true)
                        }}
                      >
                        Delete
                      </li>
                    </DropdownMenu>
                  </>
                }
              </div>
            ]
          }
        })
      }
    />
    <Modal
      open={existingProgressModalOpen}
      close={(): void => setExistingProgressModalOpen(false)}
      title={"Warning: existing set progress"}
      footer={
        <div className={"flex gap-3 justify-end"}>
          <Button theme={"secondary"} onClick={(): void => {
            localStorage.removeItem(`set.${selectedSet?.setId}`)
            setExistingProgressModalOpen(false)
            setRandomizeOrderModalOpen(true)
          }}>
            Restart training
          </Button>
          <Button onClick={(): void => navigate(`/sets/${selectedSet?.certification}/${selectedSet?.setId}?mode=training&question=1`)}>
            Continue
          </Button>
        </div>
      }
    >
      Existing progress for this set was found. Choose an option:
    </Modal>

    <Modal
      open={randomizeOrderModalOpen}
      close={(): void => setRandomizeOrderModalOpen(false)}
      title="Randomize questions order"
      footer={
        <div className="flex gap-3 justify-end">
          <Button
            theme={"secondary"}
            onClick={(): void => navigate(`/sets/${selectedSet?.certification}/${selectedSet?.setId}?mode=training&question=1`)}
          >
            Don't randomize
          </Button>
          <Button
            onClick={(): void => navigate(`/sets/${selectedSet?.certification}/${selectedSet?.setId}?mode=training&randomizeOrder=true&question=1`) }
          >
            Randomize
          </Button>
        </div>
      }
    >
      Do you want to randomize questions order (recommended)?
    </Modal>

    {
      user?.isAdmin && <Modal
        open={deleteModalOpen}
        close={(): void => setDeleteModalOpen(false)}
        title={"Confirm set deletion"}
        footer={
          <div className={"flex gap-3 justify-end"}>
            <Button theme={"tertiary"} disabled={isLoading} onClick={(): void => setDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button theme={"danger"} disabled={isLoading} onClick={async(): Promise<void> => {
              if (selectedSet) {
                setIsLoading(true)
                try {
                  await SetsService.deleteSet({ handler: selectedSet })
                  await reloadSets()
                  setDeleteModalOpen(false)
                } catch (error) {
                  handleError(error)
                } finally {
                  setIsLoading(false)
                }
              }
            }}>
              Delete
            </Button>
          </div>
        }
      >
        Are you sure you want to delete this set?
        <div className={"font-medium"}>SUBMISSIONS LINKED TO THIS SET WILL STOP WORKING!</div>
      </Modal>
    }

    {user?.isAdmin &&
      <Modal
        open={deprecateModalOpen}
        close={(): void => setDeprecateModalOpen(false)}
        title={"Confirm set deprecation status changes"}
        footer={
          <div className={"flex gap-3 justify-end"}>
            <Button
              disabled={isLoading}
              onClick={(): void => setDeprecateModalOpen(false)}
              theme={"secondary"}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              theme={"primary"}
              onClick={async(): Promise<void> => {
                if (typeof selectedSet?.deprecated !== "undefined") {
                  setIsLoading(true)
                  const { deprecated, ...handler } = selectedSet
                  try {
                    await SetsService.updateSet({ handler, updates: { deprecated: !deprecated } })
                    await reloadSets()
                    setDeprecateModalOpen(false)
                  } catch (error) {
                    handleError(error)
                  } finally {
                    setIsLoading(false)
                  }
                }
              }}
            >
              Confirm
            </Button>
          </div>
        }
      >
        {!selectedSet?.deprecated ? "Are you sure you want to deprecate this set?" : "Are you sure you want to republish this set?"}
      </Modal>
    }
  </>
}

export default SetsList