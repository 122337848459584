import classNames from "classnames"
import { useState, useRef, useEffect, FC, PropsWithChildren } from "react"
import Transition from "./transition/CSSTransition"

type Props = {
  align?: "right" | "left"
  className?: string
}

const DropdownMenu: FC<PropsWithChildren<Props>> = ({
  children,
  align = "left",
  className
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const trigger = useRef<HTMLButtonElement>(null)
  const dropdown = useRef<HTMLUListElement>(null)

  useEffect(() => {
    if (dropdown.current && dropdownOpen) {
      if (
        dropdown.current.getBoundingClientRect().bottom > window.innerHeight ||
        dropdown.current.getBoundingClientRect().top < 0
      ) {
        dropdown.current.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [dropdownOpen])

  useEffect(() => {
    const handler = ({ target }: MouseEvent): void => {
      if (
        !dropdownOpen
        || !target
        || dropdown.current?.contains(target as Node)
        || trigger.current?.contains(target as Node)
      ) {
        return
      }

      setDropdownOpen(false)
    }

    document.addEventListener("click", handler)

    return (): void => {
      document.removeEventListener("click", handler)
    }
  })

  useEffect(() => {
    const handler = ({ key }: KeyboardEvent): void => {
      if (!dropdownOpen || key === "Esc") {
        return
      }
      setDropdownOpen(false)
    }
    document.addEventListener("keydown", handler)

    return (): void => {
      document.removeEventListener("keydown", handler)
    }
  })

  return (
    <div className={classNames("absolute", className)} style={{ height: 28 }}>
      <button
        ref={trigger}
        className={classNames("text-gray-400 hover:text-gray-500 rounded-full outline-none", { "bg-gray-100 text-gray-500": dropdownOpen })}
        aria-haspopup={"true"}
        onClick={(): void => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className={"sr-only"}>Menu</span>
        <svg className={"w-8 h-8 fill-current"} viewBox={"0 0 32 32"}>
          <circle cx={"16"} cy={"16"} r={"2"} />
          <circle cx={"10"} cy={"16"} r={"2"} />
          <circle cx={"22"} cy={"16"} r={"2"} />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag={"div"}
        className={classNames("origin-top-right z-10 absolute top-full min-w-36 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-2", align === "right" ? "right-0" : "left-0")}
        enter={"transition ease-out duration-200 transform"}
        enterStart={"opacity-0 -translate-y-2"}
        enterEnd={"opacity-100 translate-y-0"}
        leave={"transition ease-out duration-200"}
        leaveStart={"opacity-100"}
        leaveEnd={"opacity-0"}
      >
        <ul
          ref={dropdown}
          onFocus={(): void => setDropdownOpen(true)}
          onBlur={(): void => setDropdownOpen(false)}
        >
          {children}
        </ul>
      </Transition>
    </div>
  )
}

export default DropdownMenu