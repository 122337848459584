import classNames from "classnames"
import { FC, ReactNode, useState } from "react"
import { applicationStatusMap, applictionStatusToClassName } from "../constants/application"
import { certificationColors, certificationsMap } from "../constants/certification"
import { getDateTime } from "../helpers/date"
import { ApplicationsSectionProps } from "../pages/Applications"
import Icon from "./Icon"
import Message from "./Message"
import Table from "./Table"
import { Application, ApplicationHandler, ApplicationsService, CloseApplicationRequestSchema } from "../sdk/certifications"
import useError from "../hooks/useError"
import Button from "./Button"
import Modal from "./Modal"
import Select, { SelectOption } from "./Select"
import { useNavigate } from "react-router-dom"
import Tag from "./Tag"
import ApplicationModal from "./ApplicationModal"
import DropdownMenu from "./DropdownEditMenu"

const applicationStatusOptions = [
  { label: "Passed", value: "PASSED" },
  { label: "Failed", value: "FAILED" }
]

const ApplicationsList: FC<ApplicationsSectionProps> = ({ applications, users, reloadApplications, section }) => {
  const { handleError } = useError()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [editedApplication, setEditedApplication] = useState<Application | null>(null)
  const [closingApplication, setClosingApplication] = useState<CloseApplicationRequestSchema | null>(null)
  const [deletingApplicationHandler, setDeletingApplicationHandler] = useState<ApplicationHandler | null>(null)

  const tableHeadings: string[] = ["Candidate", "Ceritification", "Exam timestamp", "Application Status", "Last 3 Submissions", "Actions"]

  if (section === "waiting") {
    tableHeadings.splice(3, 0, "Booking status")
  }

  return (
    applications.length > 0 ?
      <>
        <Table
          tableClassName="applications__table"
          headings={tableHeadings}
          fixedColumns={["Actions"]}
          rows={applications.map(({ application, lastSubmissions }) => {
            const { applicationId, userId, certification, examTimestamp, status, booked } = application

            const rowChildren: ReactNode[] = [
              users[userId].label,
              <Tag outline theme={certificationColors[certification]}>{certificationsMap[certification]}</Tag>,
              getDateTime(new Date(examTimestamp)),
              <span className={classNames("font-semibold", applictionStatusToClassName[status])}>
                {applicationStatusMap[status]}
              </span>,
              lastSubmissions.length > 0 ?
                lastSubmissions.map(({ correctAnswersPercentage, endTimestamp, userId, setId, submissionId, certification }) => {
                  return (
                    <div
                      className="applications__last-submission-container"
                      onClick={(): void => navigate(`/submissions/${userId}/${certification}/${setId}/${submissionId}`)}
                    >
                      <span>{correctAnswersPercentage}%</span> -
                      <span> {new Date(Date.now()).getDate() - new Date(endTimestamp).getDate()} days ago</span>
                    </div>
                  )
                })
                : "No Submissions found",
              <div className={"flex gap-3 items-center"}>
                {
                  section === "closed" ? <>
                    <Button theme={"danger-white"} onClick={(): void => setDeletingApplicationHandler({ applicationId, userId, certification })}>
                      <Icon name={"trash"} className={"mr-1"} size={14}/>
                      Delete
                    </Button>
                  </> : <>
                    <Button theme={"secondary"} onClick={(): void => setEditedApplication(application)}>
                      <Icon name={"edit"} className={"mr-1"} size={14}/>
                      Edit
                    </Button>
                    <DropdownMenu align={"right"} className="right-6">
                      <li
                        className={"cursor-pointer font-medium text-gray-600 hover:text-gray-800 flex items-center py-1 px-3"}
                        onClick={(): void => setClosingApplication({ handler: { applicationId, userId, certification }, passed: true })}
                      >
                        <Icon name={"archive"} className={"mr-2"} size={14}/>
                        Close
                      </li>
                      <li
                        className={"cursor-pointer font-medium text-red-500 hover:text-red-600 flex items-center py-1 px-3"}
                        onClick={(): void => setDeletingApplicationHandler({ applicationId, userId, certification })}
                      >
                        <Icon name={"trash"} className={"mr-2"} size={14}/>
                        Delete
                      </li>
                    </DropdownMenu>
                  </>
                }
              </div>
            ]

            if (section === "waiting") {
              rowChildren.splice(3, 0, <Tag outline theme={booked ? "blue" : "yellow"}>{booked ? "Booked" : "Pending"}</Tag>)
            }

            return {
              id: applicationId,
              children: rowChildren
            }
          })}
        />
        <Modal
          close={(): void => setClosingApplication(null)}
          open={!!closingApplication}
          title={"Close application"}
          autoClose={false}
        >
          <div className={"mb-4"}>
            <Select
              options={applicationStatusOptions}
              label={"Status"}
              defaultValue={applicationStatusOptions[0]}
              onChange={(option): void => {
                const { value } = option as SelectOption<"PASSED" | "FAILED">
                setClosingApplication(closingApplication => closingApplication ? { ...closingApplication, passed: value === "PASSED" } : null)
              }}
            />
          </div>
          <Button
            className={"w-full"}
            disabled={isLoading}
            onClick={async(): Promise<void> => {
              if (!closingApplication) {
                return
              }
              setIsLoading(true)
              try {
                await ApplicationsService.closeApplication(closingApplication)
                setClosingApplication(null)
                await reloadApplications()
              } catch (error) {
                handleError(error)
                setIsLoading(false)
              }
            }}
          >
            Submit
          </Button>
        </Modal>
        <Modal
          open={!!deletingApplicationHandler}
          close={(): void => setDeletingApplicationHandler(null)}
          title={"Delete Application"}
          footer={
            <div className={"flex gap-3 justify-end"}>
              <Button theme={"tertiary"} disabled={isLoading} onClick={(): void => setDeletingApplicationHandler(null)}>
                Cancel
              </Button>
              <Button theme={"danger"} disabled={isLoading} onClick={async(): Promise<void> => {
                if (deletingApplicationHandler) {
                  setIsLoading(true)
                  try {
                    await ApplicationsService.deleteApplication({ handler: deletingApplicationHandler })
                    setDeletingApplicationHandler(null)
                    await reloadApplications()
                  } catch (error) {
                    handleError(error)
                    setIsLoading(false)
                  }
                }
              }}>
                Delete
              </Button>
            </div>
          }
        >
          Do you want to remove permanently this application?
        </Modal>
        {
          !!editedApplication && <ApplicationModal
            open={!!editedApplication}
            application={editedApplication}
            close={(): void => setEditedApplication(null)}
            reloadApplications={reloadApplications}
          />
        }
      </>
      :
      <Message title={"No applications found"}/>
  )
}

export default ApplicationsList
