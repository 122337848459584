import { FC, ReactElement, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select, { SelectOption } from "./Select"
import { Application, ApplicationsService } from "../sdk/certifications"
import Button from "./Button"
import { certificationFilterOptions } from "../constants/certification"
import classNames from "classnames"
import Input from "./Input"
import useError from "../hooks/useError"
import { StaffUsersService } from "../sdk/principals"
import Modal from "./Modal"
import Spinner from "./Spinner"
import Checkbox from "./Checkbox"
import { getValidInputDateTime } from "../helpers/date"

type Props = {
  open: boolean
  close: () => void
  application?: Application
  reloadApplications: () => Promise<void>
}

type FormData = Omit<Application, "applicationId"> & { applicationId?: string }

const { SHOW_ALL, ...singleCertificationFilterOptions } = certificationFilterOptions

const ApplicationModal: FC<Props> = ({ application, open, close, reloadApplications }) => {
  const { handleError } = useError()
  const { handleSubmit, control, formState, watch } = useForm<FormData>({
    defaultValues: application
  })

  const [userId, certification, examTimestamp] = watch(["userId", "certification", "examTimestamp"])

  const [loading, setLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [userOptions, setUserOptions] = useState<Record<string, SelectOption<string>>>({})

  useEffect(() => {
    if (open) {
      setLoading(true)
      StaffUsersService.listStaffUsers({ active: true, unsafeUserName: true })
        .then(({ data }) => {
          setUserOptions(data.reduce((acc: Record<string, SelectOption<string>>, { userId, username }) => {
            acc[userId] = { label: username, value: userId }
            return acc
          }, {}))
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }
  }, [open])

  const onSubmit = async({ examTimestamp, booked, status = "WAITING_FOR_EXAM", ...handler }: FormData): Promise<void> => {
    setIsSubmitting(true)
    try {
      await ApplicationsService.upsertApplication({
        handler,
        updates: { examTimestamp, status, booked }
      })
      close()
      await reloadApplications()
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  return <Modal
    title={`${application ? "Edit" : "Create"} Application`}
    open={open}
    close={close}
    autoClose={false}
  >
    {loading ?
      <div className="flex justify-center items-center my-12">
        <Spinner size={30} />
      </div>
      :
      <form className={classNames(isSubmitting && "pointer-events-none opacity-50")} onSubmit={(handleSubmit(onSubmit))}>
        <div className={"flex gap-3"}>
          <Controller
            control={control}
            name={"userId"}
            rules={{ required: "Required" }}
            render={({ field }): ReactElement => {
              return (
                <Select
                  options={Object.values(userOptions)}
                  label={"User"}
                  className={"w-1/2"}
                  error={formState.errors.userId?.message}
                  isSearchable={true}
                  {...field}
                  value={userOptions[userId]}
                  onChange={(option): void => field.onChange((option as { value: string, label: string }).value)}
                />
              )
            }}
          />
          <Controller
            control={control}
            name="certification"
            rules={{ required: "Required" }}
            render={({ field }): ReactElement => {
              return (
                <Select
                  options={Object.values(singleCertificationFilterOptions)}
                  className={"w-1/2"}
                  label="Certification"
                  error={formState.errors.certification?.message}
                  isSearchable={true}
                  {...field}
                  value={singleCertificationFilterOptions[certification]}
                  onChange={(option): void => field.onChange((option as { value: string, label: string }).value)}
                />
              )
            }}
          />
        </div>

        <Controller
          rules={{ required: "Required" }}
          control={control}
          name={"examTimestamp"}
          render={({ field }): ReactElement => {
            return (
              <Input
                className={"mt-3 w-full"}
                label={"Exam Date"}
                type={"datetime-local"}
                {...field}
                {...!!examTimestamp && { value: getValidInputDateTime(new Date(examTimestamp)) }}
                onChange={(value): void => {
                  field.onChange(Date.parse(value))
                }}
                error={formState.errors.examTimestamp?.message}
              />
            )
          }}
        />

        <Controller
          control={control}
          defaultValue={false}
          name="booked"
          render={({ field: { onChange, value } }): ReactElement => {
            return <>
              <div className={"input__label mt-4"}>Booking status</div>
              <Checkbox
                onChange={(e): void => {
                  onChange(e.target.checked)
                }}
                label="Booked"
                id={"booked"}
                checked={value}
                containerClassName="flex items-center gap-x-2 mt-3"
              />
            </>
          }}
        />
        <Button
          type={"submit"}
          disabled={isSubmitting || loading || !userId || !certification || !examTimestamp}
          className={"w-full mt-4"}
        >
          Submit
        </Button>
      </form>
    }
  </Modal>
}

export default ApplicationModal