import { FC, useState, useEffect, useRef } from "react"
import Spinner from "../components/Spinner"
import useError from "../hooks/useError"
import { SubmissionsService, Submission, SetsService, ListSubmissionsRequestSchema } from "../sdk/certifications"
import { certificationColors, CertificationFilter, certificationFilterOptions, certificationsMap } from "../constants/certification"
import Select, { SelectOption } from "../components/Select"
import Input from "../components/Input"
import useAuth from "../hooks/useAuth"
import Button from "../components/Button"
import { StaffUsersService } from "../sdk/principals"
import useIsInitialRender from "../hooks/useIsInitialRender"
import Table, { TableRow } from "../components/Table"
import { useNavigate, useSearchParams } from "react-router-dom"
import Message from "../components/Message"
import { MINUTE } from "../helpers/time"
import Tag from "../components/Tag"

const isExamFilterOptions = {
  SHOW_ALL: { label: "All results", value: "SHOW_ALL" },
  TRAINING: { label: "Training", value: false },
  EXAM: { label: "Exam", value: true }
}

type SubmissionsFilters = Exclude<ListSubmissionsRequestSchema["filters"], undefined>

const parseQueryFilters = (searchParams: URLSearchParams): SubmissionsFilters => {
  const submissionFilters = {}

  const entries = Array.from(searchParams.entries())
  entries.forEach(([key, value]) => {
    switch (key) {
      case "from":
        Object.assign(submissionFilters, { from: parseInt(value) })
        break
      case "isExam":
        Object.assign(submissionFilters, { isExam: value === "true" })
        break
      default:
        Object.assign(submissionFilters, { [key]: value })
    }
  })

  return submissionFilters
}

const SubmissionsPage: FC = () => {
  const { handleError } = useError()
  const { user } = useAuth()
  const navigate = useNavigate()
  const firstLoad = useIsInitialRender()
  const [searchParams, setSearchParams] = useSearchParams()

  const [loading, setLoading] = useState<boolean>(true)
  const [submissions, setSubmissions] = useState<Submission[]>([])
  const [userFilterOptions, setUserFilterOptions] = useState<Record<string, SelectOption<string>>>({})
  const [setsFilterOptions, setSetsFilterOptions] = useState<Record<string, SelectOption<string>>>({})
  const [filters, setFilters] = useState<SubmissionsFilters>({})
  const fromInputRef = useRef<HTMLInputElement>(null)
  const [submissionsIdsToNamesMap, setSubmissionsIdsToNamesMap] = useState<{[setId: string]: string}>()

  const showClearFilters = Object.keys(filters).length > (user?.isAdmin ? 0 : 1)

  useEffect(() => {
    if (!user) {
      return
    }

    void (async(): Promise<void> => {
      try {
        const filters: SubmissionsFilters = parseQueryFilters(searchParams)
        if (!user.isAdmin) {
          filters.userId = user.userId
        }
        if (filters.from && fromInputRef.current) {
          fromInputRef.current.value = new Date(filters.from).toISOString().split("T")[0]
        }
        setFilters(filters)

        const [{ data: users }, { data: submissions }] = await Promise.all([
          StaffUsersService.listStaffUsers({ active: true, unsafeUserName: true }),
          SubmissionsService.listSubmissions({
            ...!!Object.keys(filters).length && { filters }
          })
        ])

        setUserFilterOptions({
          SHOW_ALL: { label: "All users", value: "SHOW_ALL" },
          ...users.reduce((acc: Record<string, SelectOption<string>>, { userId, username }) => {
            acc[userId] = { label: username, value: userId }
            return acc
          }, {})
        })

        setSubmissions(submissions)

        const sets = await Promise.all(submissions.map(({ setId, certification }) => {
          return SetsService.getSet({
            handler: {
              setId,
              certification
            }
          })
        }))

        setSubmissionsIdsToNamesMap(sets.reduce((prev, current) => {
          return { ...prev, [current.data.setId]: current.data.name }
        }, {}))
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [user])

  useEffect(() => {
    const result: Record<string, string> = {}
    Object.keys(filters).forEach(key => {
      result[key] = filters[key as keyof SubmissionsFilters]!.toString()
    })

    setSearchParams(new URLSearchParams(result))
  }, [filters])

  useEffect(() => {
    if (firstLoad || loading) {
      return
    }

    void (async(): Promise<void> => {
      setLoading(true)
      try {
        const { data } = await SubmissionsService.listSubmissions({ filters })
        setSubmissions(data)
      } catch (error) {
        setSubmissions([])
        handleError(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [filters])

  useEffect(() => {
    if (filters.certification) {
      SetsService.listSets({ filters: { certification: filters.certification } })
        .then(({ data }) => {
          setSetsFilterOptions({
            SHOW_ALL: { label: "All sets", value: "SHOW_ALL" },
            ...data.reduce((acc: Record<string, SelectOption<string>>, { setId, name }) => {
              acc[setId] = { label: name, value: setId }
              return acc
            }, {})
          })
        })
        .catch(handleError)
    }
  }, [filters.certification])

  return (
    <>
      <h1 className={"page-title"}>Submissions</h1>
      <div className={"my-8 flex items-center"}>
        {
          user?.isAdmin && <>
            <Select
              className={"hover:cursor-text w-60 mr-6"}
              label={"User"}
              value={userFilterOptions[filters.userId ?? "SHOW_ALL"]}
              options={Object.values(userFilterOptions)}
              isSearchable
              onChange={(option): void => {
                const { value } = option as SelectOption<string>
                setFilters(filters => {
                  const newFilters = { ...filters }
                  if (value === "SHOW_ALL") {
                    delete newFilters.userId
                    delete newFilters.certification
                  } else {
                    newFilters.userId = value
                  }

                  return newFilters
                })
              }}
            />
            {
              !!filters.userId && <Select
                className={"mr-6 w-60"}
                label={"Certification"}
                value={certificationFilterOptions[filters.certification ?? "SHOW_ALL"]}
                options={Object.values(certificationFilterOptions)}
                onChange={(option): void => {
                  const { value } = option as SelectOption<CertificationFilter>
                  setFilters(filters => {
                    const newFilters = { ...filters }
                    if (value === "SHOW_ALL") {
                      delete newFilters.certification
                      delete newFilters.setId
                    } else {
                      newFilters.certification = value
                    }

                    return newFilters
                  })
                }}
              />
            }
            {
              !!filters.certification && <Select
                className={"mr-6 w-52"}
                label={"Set"}
                value={setsFilterOptions[filters.setId ?? "SHOW_ALL"]}
                options={Object.values(setsFilterOptions)}
                onChange={(option): void => {
                  const { value } = option as SelectOption<CertificationFilter>
                  setFilters(filters => {
                    const newFilters = { ...filters }
                    if (value === "SHOW_ALL") {
                      delete newFilters.setId
                    } else {
                      newFilters.setId = value
                    }

                    return newFilters
                  })
                }}
              />
            }
          </>
        }
        <Input
          className={"mr-6 w-52"}
          type={"date"}
          label={"From"}
          inputRef={fromInputRef}
          onChange={(value): void => {
            setFilters(filters => {
              const newFilters = { ...filters }
              if (value) {
                newFilters.from = Date.parse(value)
              } else {
                delete newFilters.from
              }

              return newFilters
            })
          }}
        />
        <Select
          className={"w-52"}
          label={"Submission type"}
          value={isExamFilterOptions[typeof filters.isExam === "undefined" ? "SHOW_ALL" : (filters.isExam ? "EXAM" : "TRAINING")]}
          options={Object.values(isExamFilterOptions)}
          onChange={(option): void => {
            const { value } = option as SelectOption<"SHOW_ALL" | boolean>
            setFilters(filters => {
              const newFilters = { ...filters }
              if (value === "SHOW_ALL") {
                delete newFilters.isExam
              } else {
                newFilters.isExam = value
              }

              return newFilters
            })
          }}
        />
        {
          showClearFilters && <Button
            className={"ml-auto self-end"}
            style={{ height: 38 }}
            onClick={(): void => setFilters(user?.isAdmin ? {} : { userId: filters.userId }) }
          >
            Clear all filters
          </Button>
        }
      </div>
      {
        loading
          ? <div className={"flex justify-center items-center w-full"}><Spinner className={"lg:mt-64"}/></div>
          : submissions.length > 0
            ? <Table
              headings={(user?.isAdmin ? ["User"] : []).concat(["Certification", "Set", "Date", "Submitted after", "Correct answers", "Status", "Type"])}
              rows={submissions.map(({ userId, setId, submissionId, totalQuestions, certification, passed, correctAnswersPercentage, isExam, endTimestamp, startTimestamp, correctAnswers }) => {
                const row: TableRow = {
                  id: submissionId,
                  onRowClick: (): void => {
                    navigate(`/submissions/${userId}/${certification}/${setId}/${submissionId}`)
                  },
                  children: []
                }

                if (user?.isAdmin) {
                  row.children.push(userFilterOptions[userId].label)
                }

                row.children.push(
                  <Tag theme={certificationColors[certification]} outline>{certificationsMap[certification]}</Tag>,
                  <span className="mr-6">{submissionsIdsToNamesMap ? submissionsIdsToNamesMap[setId] : "Set not found"}</span>,
                  new Date(startTimestamp).toLocaleDateString("it-IT"),
                  `${Math.round((endTimestamp - startTimestamp) / MINUTE)} minutes`,
                  `${correctAnswers} / ${totalQuestions} (${correctAnswersPercentage}%)`,
                  <Tag theme={passed ? "green" : "red"}>{passed ? "Passed" : "Failed"}</Tag>,
                  isExam ? "Exam" : "Training"
                )

                return row
              })}
            />
            : <Message title={"No submissions found"} className={"lg:mt-64"} icon={"alert-circle"}/>
      }
    </>
  )
}

export default SubmissionsPage
