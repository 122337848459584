import { FC, useState, useEffect } from "react"
import { ListSetsRequestSchema, Set, SetsService } from "../sdk/certifications"
import SetsList from "../components/SetsList"
import useError from "../hooks/useError"
import Spinner from "../components/Spinner"
import Select, { SelectOption } from "../components/Select"
import { CertificationFilter, certificationFilterOptions } from "../constants/certification"
import Message from "../components/Message"
import Checkbox from "../components/Checkbox"

const SetsPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [sets, setSets] = useState<Set[]>([])
  const [certification, setCertification] = useState<CertificationFilter>("SHOW_ALL")
  const [showDeprecated, setShowDeprecated] = useState<boolean>(false)

  const { handleError } = useError()

  const loadSets = async(): Promise<void> => {
    try {
      setLoading(true)
      const filters: ListSetsRequestSchema["filters"] = { showDeprecated }
      if (certification !== "SHOW_ALL") {
        filters.certification = certification
      }
      const { data } = await SetsService.listSets({ filters })
      setSets(data.sort((a, b) => `${a.certification} ${a.name}` > `${b.certification} ${b.name}` ? 1 : -1))

    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void loadSets()
  }, [certification, showDeprecated])

  return (
    <div className={"flex flex-col h-full"}>
      <h1 className="page-title">Sets</h1>
      <div className="my-8 flex items-end justify-start">
        <Select
          className={"w-60"}
          value={certificationFilterOptions[certification]}
          options={Object.values(certificationFilterOptions)}
          label={"Certification"}
          onChange={(option): void => setCertification((option as SelectOption<CertificationFilter>).value) }
        />

        <Checkbox
          label={"Show deprecated sets"}
          checked={showDeprecated}
          onChange={(): void => setShowDeprecated(!showDeprecated)}
          containerClassName="items-center flex gap-x-2 ml-3 mb-2"
          id="showDeprecatedCheckbox"
        />
      </div>
      {
        loading
          ? <div className="w-full mt-12 flex flex-1 justify-center items-center">
            <Spinner />
          </div>
          :
          sets.length > 0
            ? <SetsList sets={sets} reloadSets={loadSets}/>
            : <Message className={"mt-48"} title={"No sets found"} icon={"alert-circle"}/>
      }
    </div>
  )
}

export default SetsPage
