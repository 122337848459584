import { ComponentType } from "react"
import Login from "../pages/Login"
import { SidebarGroup, SidebarLink, SidebarSection, WrapperType } from "../types/routes"
import NotFound from "../pages/NotFound"
import MFAPage from "../pages/MFA"
import SetsPage from "../pages/Sets"
import SubmissionsPage from "../pages/Submissions"
import SetPage from "../pages/Set"
import Submission from "../pages/Submission"
import { IconName } from "../types/icon"
import QuestionsPage from "../pages/Questions"
import ApplicationsPage from "../pages/Applications"

// USER BASED REDIRECTS
export const authRedirectPath = "/login"
export const privateRedirectPath = "/"

// TYPES
type SidebarSectionId = keyof typeof sidebarSectionsConfig
type SidebarGroupId = keyof typeof sidebarGroupsConfig
type SidebarOptions = {
  label: string
  section: SidebarSectionId
  group?: SidebarGroupId
  icon?: IconName
  restrictAccessToAdmin?: boolean
}
type SectionsConfig = {
  [key in SidebarSectionId]: {
    label?: string
  }
}
type GroupsConfig = {
  [key in SidebarGroupId]: {
    label: string
    icon?: ComponentType
  }
}
type Route = {
  path: string
  component: ComponentType
  wrapper: WrapperType
  sidebarOptions?: SidebarOptions
  withHeader?: boolean
}

// CONFIGS
export const routes: Route[] = [
  {
    path: "/login",
    component: Login,
    wrapper: "auth"
  },
  {
    path: "/mfa",
    component: MFAPage,
    wrapper: "auth"
  },
  {
    path: "/sets",
    component: SetsPage,
    wrapper: "private",
    sidebarOptions: {
      label: "Sets",
      section: "main",
      icon: "clipboard-list"
    }
  },
  {
    path: "/submissions",
    component: SubmissionsPage,
    wrapper: "private",
    sidebarOptions: {
      label: "Submissions",
      section: "main",
      icon: "chart-dots"
    }
  },
  {
    path: "/submissions/:userId/:certification/:setId/:submissionId",
    component: Submission,
    wrapper: "private"
  },
  {
    path: "/questions",
    component: QuestionsPage,
    wrapper: "private",
    sidebarOptions: {
      label: "Questions",
      section: "main",
      icon: "zoom-question"
    }
  },
  {
    path: "/sets/:certification/:setId",
    component: SetPage,
    wrapper: "private",
    withHeader: false
  },
  {
    path: "/applications",
    component: ApplicationsPage,
    wrapper: "private",
    sidebarOptions: {
      restrictAccessToAdmin: true,
      label: "Applications",
      section: "main",
      icon: "users"
    }
  },
  {
    path: "*",
    component: NotFound,
    wrapper: "public"
  }
]
export const sidebarSectionsConfig = {
  main: {},
  pages: {
    label: "Pages"
  }
}
export const sidebarGroupsConfig = {
  group: {
    label: "Group"
  }
}
/*
  Injects types properly, we need both:
  - typed config to achieve stricter type checking inside `getSidebarSections` and `addRoute`
  - untyped config to exctract exact keys for SidebarSectionId and SidebarGroupId
*/
const sectionsConfig = sidebarSectionsConfig as SectionsConfig
const groupsConfig = sidebarGroupsConfig as GroupsConfig

// HELPERS
export const addRoute = (section: (SidebarGroup | SidebarLink)[], route: Route): void => {
  const sidebarOptions = route.sidebarOptions!
  if (sidebarOptions.group) {
    const groupId = sidebarOptions.group
    const group = section.find((item) => (item as SidebarGroup).id === sidebarOptions.group) as SidebarGroup
    if (group) {
      // add route to existing group
      group.children.push({ path: route.path, label: sidebarOptions.label })
    } else {
      // create group and add route as its first child
      section.push({
        id: groupId,
        label: groupsConfig[groupId].label,
        icon: groupsConfig[groupId].icon,
        children: [{ path: route.path, label: sidebarOptions.label }]
      } as SidebarGroup)
    }
  } else {
    // add route as SidebarLink
    section.push({
      path: route.path,
      label: sidebarOptions.label,
      icon: sidebarOptions.icon,
      adminAccess: sidebarOptions.restrictAccessToAdmin
    })
  }
}
const getSidebarSections = (): SidebarSection[] => {
  const mapping = routes.reduce((sections, route) => {
    if (route.sidebarOptions) {
      const sectionId = route.sidebarOptions.section
      if (sections[sectionId]) {
        addRoute(sections[sectionId], route)
      } else {
        sections[sectionId] = []
        addRoute(sections[sectionId], route)
      }
    }
    return sections
  }, {} as { [key in SidebarSectionId]: (SidebarGroup | SidebarLink)[] })

  return Object.keys(mapping).map((key: string) => {
    const k = key as SidebarSectionId
    return {
      label: sectionsConfig[k].label,
      items: mapping[k]
    }
  })
}

export const sidebarSections = getSidebarSections()
