import { FC, useEffect, useState } from "react"
import { ListQuestionsRequestSchema, Question, QuestionsService, SetsService, Tag as QuestionTag, TagsService } from "../sdk/certifications"
import useError from "../hooks/useError"
import Message from "../components/Message"
import QuestionCard, { QUESTION_TYPE } from "../components/QuestionCard"
import { UpdateAnswer } from "./Set"
import Select, { SelectOption } from "../components/Select"
import { certificationFilterOptions, CertificationFilter, certificationsMap } from "../constants/certification"
import Spinner from "../components/Spinner"
import { localStorage } from "../helpers/storage"
import Button from "../components/Button"
import { useSearchParams } from "react-router-dom"
import Tag from "../components/Tag"
import { produce } from "immer"
import useIsInitialRender from "../hooks/useIsInitialRender"
import QuestionTags from "../components/QuestionTags"

type QuestionFilters = Exclude<ListQuestionsRequestSchema["filters"], undefined>

const parseQueryFilters = (searchParams: URLSearchParams): QuestionFilters => {
  const questionFilters = {}

  const entries = Array.from(searchParams.entries())
  entries.forEach(([key, value]) => {
    switch (key) {
      case "certification":
      case "tagIds":
        Object.assign(questionFilters, { [key]: value?.split(",") ?? [] })
        break
      default:
        Object.assign(questionFilters, { [key]: value })
    }
  })

  return questionFilters
}

const QuestionsPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const firstLoad = useIsInitialRender()
  const { handleError } = useError()

  const [loading, setLoading] = useState<boolean>(true)
  const [setNames, setSetNames] = useState<Record<string, string>>({})
  const [filters, setFilters] = useState<QuestionFilters>({})
  const [tagFilters, setTagFilters] = useState<QuestionTag[]>([])
  const [tagSuggestions, setTagSuggestions] = useState<QuestionTag[]>([])
  const [questions, setQuestions] = useState<Question[]>([])
  const [answers, setAnswers] = useState<Record<string, string[]>>({})
  const [setsFilterOptions, setSetsFilterOptions] = useState<Record<string, SelectOption<string>>>({})
  const [continuationToken, setContinuationToken] = useState<string | null>(null)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)

  useEffect(() => {
    setContinuationToken(null)
  }, [filters.certification, filters.setId, filters.tagIds])

  const getCachedData = (): Record<string, string[]> => {
    let cachedData = localStorage.getItem("questionsPageAnswers", "safe")
    if (!cachedData) {
      cachedData = answers
      localStorage.setItem("questionsPageAnswers", cachedData)
    }

    return cachedData
  }

  const generateAnswersSkeleton = (questions: Question[]): Record<string, string[]> => questions.reduce((acc: Record<string, string[]>, { questionId }) => {
    acc[questionId] = []
    return acc
  }, {})

  const updateAnswer: UpdateAnswer = (payload) => {
    const cachedData = getCachedData()

    setAnswers(answers => {
      const newAnswers = { ...answers }

      if (!payload.multiple) {
        newAnswers[payload.questionId] = [payload.answerId]
        cachedData[payload.questionId] = [payload.answerId]
      } else if (payload.checked) {
        newAnswers[payload.questionId] = [...newAnswers[payload.questionId] || [], payload.answerId]
        cachedData[payload.questionId] = [...cachedData[payload.questionId] || [], payload.answerId]
      } else {
        newAnswers[payload.questionId] = newAnswers[payload.questionId].filter(answerId => payload.answerId !== answerId)
        cachedData[payload.questionId] = cachedData[payload.questionId].filter(answerId => payload.answerId !== answerId)
      }

      localStorage.setItem("questionsPageAnswers", cachedData)
      return newAnswers
    })
  }

  const getQuestions = async(): Promise<void> => {
    try {
      const req: ListQuestionsRequestSchema = {
        ...!!Object.keys(filters).length && { filters },
        limit: 10
      }

      if (typeof continuationToken === "string") {
        req.continuationToken = continuationToken
      }

      const { data: returnedQuestions, continuationToken: token } = await QuestionsService.listQuestions(req)

      setContinuationToken(token || null)
      if (returnedQuestions.length > 0) {
        setQuestions([...questions, ...returnedQuestions])
      }

    } catch (err) {
      handleError(err)
    }
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      try {
        const filters: QuestionFilters = parseQueryFilters(searchParams)
        setFilters(filters)

        const { data: tags } = await TagsService.listTags({})

        setTagFilters(filters.tagIds?.map(tagId => ({ tagId, name: tags.find(e => e.tagId === tagId)?.name ?? "" })) ?? [])

        const { data: sets } = await SetsService.listSets({})
        setSetNames(sets.reduce((acc: Record<string, string>, { setId, name }) => {
          acc[setId] = name
          return acc
        }, {}))

        setTagSuggestions(tags)

        await getQuestions()

        const answers = localStorage.getItem("questionsPageAnswers") || generateAnswersSkeleton(questions)
        setAnswers(answers)
        localStorage.setItem("questionsPageAnswers", answers)
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    const result: Record<string, string> = {}
    Object.keys(filters).forEach(key => {
      const filterKey = key as keyof QuestionFilters
      switch (filterKey) {
        case "certification":
        case "tagIds":
          if (filters[filterKey]?.length) {
            result[filterKey] = filters[filterKey]!.join(",")
          }
          break
        default:
          result[key] = filters[filterKey]!.toString()
      }
    })

    setSearchParams(new URLSearchParams(result))
  }, [filters])

  useEffect(() => {
    if (firstLoad || loading) {
      return
    }

    void (async(): Promise<void> => {
      setLoading(true)
      try {
        const { data } = await QuestionsService.listQuestions({ filters })
        setQuestions(data)
      } catch (error) {
        setQuestions([])
        handleError(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [filters])

  useEffect(() => {
    if (filters.certification) {
      SetsService.listSets({ filters: { certification: filters.certification[0] } })
        .then(({ data }) => {
          setSetsFilterOptions({
            SHOW_ALL: { label: "All sets", value: "SHOW_ALL" },
            ...data.reduce((acc: Record<string, SelectOption<string>>, { setId, name }) => {
              acc[setId] = { label: name, value: setId }
              return acc
            }, {})
          })
        })
        .catch(handleError)
    }
  }, [filters.certification])

  useEffect(() => {
    setFilters(filters => ({ ...filters, tagIds: tagFilters.length ? tagFilters.map(({ tagId }) => tagId) : undefined }))
  }, [tagFilters])

  const reloadTags = (): Promise<void> => TagsService.listTags({})
    .then(({ data }) => setTagSuggestions(data))
    .catch(handleError)

  return (
    <div className={"flex flex-col h-full"}>
      <div className="flex items-center mb-8">
        <h1 className="page-title">Questions</h1>
        {
          questions.length > 0 && <Button
            className={"ml-auto"}
            onClick={(): void => {
              const cleanAnswers = generateAnswersSkeleton(questions)
              setAnswers(cleanAnswers)
              localStorage.setItem("questionsPageAnswers", cleanAnswers)
            }}
            disabled={!Object.values(answers).some(markedAnswerIds => !!markedAnswerIds.length)}
          >
            Clear Progress
          </Button>
        }
      </div>

      <div className={"flex gap-6 mb-8"}>
        <Select
          label={"Certification"}
          className={"w-60"}
          value={certificationFilterOptions[filters.certification?.[0] ?? "SHOW_ALL"]}
          options={Object.values(certificationFilterOptions)}
          onChange={(option): void => {
            const { value } = option as SelectOption<CertificationFilter>
            setFilters(filters => {
              const newFilters = { ...filters }
              if (value === "SHOW_ALL") {
                delete newFilters.certification
                delete newFilters.setId
              } else {
                newFilters.certification = [value]
              }

              return newFilters
            })
          }}
        />
        {
          filters.certification && <Select
            options={Object.values(setsFilterOptions)}
            value={setsFilterOptions[filters.setId ?? "SHOW_ALL"]}
            label={"Set"}
            className={"w-52"}
            onChange={(option): void => {
              const { value } = option as SelectOption<string>
              setFilters(filters => {
                const newFilters = { ...filters }
                if (value && value !== "SHOW_ALL") {
                  newFilters.setId = value
                } else {
                  delete newFilters.setId
                }
                return newFilters
              })
            }}
          />
        }

        <div>
          <div className="font-medium text-sm">Tags</div>
          <div className={"mt-3"}>
            <QuestionTags
              questionTags={tagFilters}
              tagSuggestions={tagSuggestions}
              reloadTags={reloadTags}
              upsertTag={(payload): void => {
                setTagFilters(tags => {
                  const tagIndex = tags.findIndex(tag => tag.tagId === payload.tagId)

                  if (tagIndex === -1) {
                    return [...tags, payload]
                  } else {
                    tags[tagIndex] = payload
                    return [...tags]
                  }
                })
              }}
              deleteTag={(tagIndex): void => {
                setTagFilters(tags => tags.filter((_, index) => index !== tagIndex))
              }}
              allowTagCreation={false}
            />
          </div>
        </div>
      </div>

      <div className={"flex items-center justify-center"}>
        {
          loading ?
            <Spinner />
            :
            questions && Object.entries(questions).length > 0 ?
              <div>
                {questions.map((question, index) => {
                  return (
                    <QuestionCard
                      heading={
                        <div className="flex items-center gap-x-3">
                          <Tag theme={"blue"} outline>{certificationsMap[question.certification]}</Tag><b>{setNames[question.setId]}</b>
                        </div>
                      }
                      key={question.questionId}
                      text={question.text}
                      multiple={question.multiple}
                      questionId={question.questionId}
                      type={QUESTION_TYPE.QUESTIONS_PAGE}
                      fullExplanation={question.explanation}
                      answers={question.answers}
                      updateAnswer={updateAnswer}
                      markedAnswerIds={answers[question.questionId] ?? []}
                      showAnswersButton={true}
                      showTags
                      questionTags={question.tags ?? []}
                      tagSuggestions={tagSuggestions}
                      upsertTag={(payload): void => {
                        setQuestions(produce(questions, state => {
                          const tagIndex = state[index].tags?.findIndex(({ tagId }) => tagId === payload.tagId) ?? 0
                          if (tagIndex !== -1) {
                            state[index].tags![tagIndex].name = payload.name
                          } else {
                            state[index].tags?.push(payload)
                          }

                          QuestionsService.setQuestionTags(({
                            handler: { setId: state[index].setId, questionId: state[index].questionId },
                            tagIds: state[index].tags?.map(({ tagId }) => tagId) ?? []
                          }))
                            .catch(handleError)
                        }))
                      }}
                      deleteTag={(tagIndex): void => {
                        setQuestions(produce(questions, state => {
                          state[index].tags?.splice(tagIndex, 1)

                          QuestionsService.setQuestionTags(({
                            handler: { setId: state[index].setId, questionId: state[index].questionId },
                            tagIds: state[index].tags?.map(({ tagId }) => tagId) ?? []
                          }))
                            .catch(handleError)
                        }))
                      }}
                      reloadTags={reloadTags}
                    />
                  )
                })}
                {continuationToken &&
                  <div className="flex justify-center my-4 w-full">
                    <Button theme="secondary" onClick={async(): Promise<void> => {
                      setLoadingMore(true)
                      await getQuestions()
                      setLoadingMore(false)
                    }}>
                      {loadingMore &&
                        <Spinner className="mr-2" size={15} />
                      }
                      Load more
                    </Button>
                  </div>
                }
              </div>
              :
              <Message title={"No questions found"} icon={"alert-circle"} />
        }
      </div>
    </div>
  )
}

export default QuestionsPage